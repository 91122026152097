<template>
  <div class="register">
    <a-form
      name="custom-validation"
      ref="formRef"
      :model="formState"
      :rules="rules"
      v-bind="layout"
      :labelCol="{span: 6, offset: 0}"
      @finish="handleFinish"
      @finishFailed="handleFinishFailed"
    >
      <a-form-item has-feedback label="手机号" name="mobile">
        <a-input v-model:value="formState.mobile" />
      </a-form-item>
      <a-form-item has-feedback label="验证码" name="code">
        <a-input-group >
          <a-row :gutter="24">
            <a-col :span="16">
              <a-input v-model:value="formState.code" size="default"/>
            </a-col>
            <a-col :span="8">
              <a-button :disabled="amendCode.flag?true:amendCode.numFlag?true:false" type="primary" @click="checkCode" block>{{amendCode.numFlag?amendCode.num:'发送'}}</a-button>
            </a-col>
          </a-row>
        </a-input-group>
      </a-form-item>
      <a-form-item has-feedback label="密码" name="user_pwd">
        <a-input
          v-model:value="formState.user_pwd"
          type="password"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="确认密码" name="checkPass">
        <a-input
          v-model:value="formState.checkPass"
          type="password"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item has-feedback label="姓名" name="name">
        <a-input v-model:value="formState.name" />
      </a-form-item>
      <a-form-item has-feedback label="邮箱" name="email">
        <a-input v-model:value="formState.email" />
      </a-form-item>
      <a-form-item has-feedback label="公司名称" name="corp_name">
        <a-input v-model:value="formState.corp_name" />
      </a-form-item>
      <a-form-item has-feedback label="办公地址" name="address">
        <a-textarea v-model:value="formState.address" :rows="2" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 10, offset: 7 }">
        <a-button type="primary" html-type="submit">注册</a-button>
        <a-button style="margin-left: 20px" @click="resetForm">重置</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { message } from 'ant-design-vue';
import { defineComponent, getCurrentInstance, reactive, ref } from 'vue'
import { register, regCode } from '../../api';
export default defineComponent({
  setup () {
    const { proxy } = getCurrentInstance()
    const formRef = ref()
    const formState = reactive({
      mobile: '',
      code: '',
      user_pwd: '',
      checkPass: '',
      name: '',
      email: '',
      corp_name: '',
      address: ''
    })
    const amendCode = ref({
      flag: true,
      num: 60,
      numFlag: false,
      code: 0,
      mobile: '',
      mobileFlag: false
    })

    const user_pwd = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('请输入密码'))
      } else {
        if (value.length >= 5) {
          formRef.value.validateFields('checkPass')
        } else {
          return Promise.reject(new Error('请输入长度为5-14位密码'))
        }

        return Promise.resolve()
      }
    }

    const validatePass2 = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('请重新输入密码'))
      } else if (value !== formState.user_pwd) {
        return Promise.reject(new Error('两个输入不匹配!'))
      } else {
        return Promise.resolve()
      }
    }
    const checkName = async (rule, value) => {
      if (!value) {
        return Promise.reject(new Error('请输入姓名'))
      }
      return Promise.resolve()
    }
    const checkMobile = async (rule, value) => {
      if (amendCode.value.mobileFlag) {
        amendCode.value.mobileFlag = false
        return Promise.reject(new Error('手机号已注册！'))
      }
      if (!value) {
        amendCode.value.flag = true
        return Promise.reject(new Error('请输入手机号'))
      }
      const patt = /1(3[0-9]|4[0,1,4-9]|5[0-3,5-9]|6[2,5-7]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}/
      if (patt.test(value) && value.length > 10) {
        amendCode.value.flag = false
        return Promise.resolve()
      } else {
        amendCode.value.flag = true
        return Promise.reject(new Error('手机号'))
      }
    }
    const checkEmail = async (rule, value) => {
      if (!value) {
        return Promise.reject(new Error('请输入邮箱'))
      }
      const patt = /^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/
      if (patt.test(value) && value.length > 6) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error('请输入正确的邮箱'))
      }
    }
    const corp_name = async (rule, value) => {
      if (!value) {
        return Promise.reject(new Error('请输入公司名称'))
      }
      return Promise.resolve()
    }
    const address = async (rule, value) => {
      if (!value) {
        return Promise.reject(new Error('请输入办公地址'))
      }
      return Promise.resolve()
    }

    const rules = {
      user_pwd: [
        {
          validator: user_pwd,
          trigger: 'change'
        }
      ],
      checkPass: [
        {
          validator: validatePass2,
          trigger: 'change'
        }
      ],
      name: [
        {
          validator: checkName,
          trigger: 'change'
        }
      ],
      mobile: [
        {
          validator: checkMobile,
          trigger: 'change'
        }
      ],
      email: [
        {
          validator: checkEmail,
          trigger: 'change'
        }
      ],
      corp_name: [
        {
          validator: corp_name,
          trigger: 'change'
        }
      ],
      address: [
        {
          validator: address,
          trigger: 'change'
        }
      ]
    }
    const layout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    }

    const handleFinish = async (values) => {
      if (values.code != amendCode.value.code) {
        amendCode.value.mobileFlag = true
        formRef.value.validateFields('mobile')
        return
      }
      const res = await register(values)
      message.success('注册成功！')
      proxy.$root.$router.push({ name: 'login' })
    }

    const handleFinishFailed = (errors) => {
    }

    const resetForm = () => {
      formRef.value.resetFields()
    }
    const checkCode = async()=>{
      amendCode.value.flag=true
      amendCode.value.numFlag=true
      let times = setInterval(()=>{
        amendCode.value.num--
        if (amendCode.value.num == 0) {
          amendCode.value.num = 60
          amendCode.value.flag=false
          amendCode.value.numFlag=false
          clearInterval(times)
        }
      },1000)
      amendCode.value.mobile = formState.mobile
      const res = await regCode(formState.mobile)
      if (res.code == '该电话号已注册') {
        amendCode.value.mobileFlag = true
        amendCode.value.num = 60
        amendCode.value.flag=false
        amendCode.value.numFlag=false
        clearInterval(times)
        formRef.value.validateFields('mobile')
      }
      amendCode.value.code = res.code
    }

    return {
      formState,
      formRef,
      rules,
      layout,
      handleFinishFailed,
      handleFinish,
      resetForm,
      checkCode,
      amendCode
    }
  }
})
</script>

<style lang="less" scoped>
.register{
  min-width: 320px;
  width: 500px;
  margin: 0 auto;
}
</style>